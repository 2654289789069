import React, { Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import ArcLeftM from '../../svg/arc-left-m.inline.svg';
import ArcLeft from '../../svg/arc-left.inline.svg';
import ArcRightM from '../../svg/arc-right-m.inline.svg';
import ArcRight from '../../svg/arc-right.inline.svg';
import useViewport from '../../utils/useViewport';
import HorizontalDash from './HorizontalDash';

const Wrapper = styled.div`
    grid-column: 2/3;
    z-index: 2;
    &:not(.anim) {
        .title-anim,
        .p-anim {
            transform: translateY(100px);
            opacity: 0;
        }
        .dash-anim {
            div {
                width: 0;
            }
        }
        .container-arc {
            svg {
                path {
                    stroke-dashoffset: 800;
                }
            }
        }
    }
    .p-anim {
        transition: all 500ms ease-in-out 600ms;
    }
    .title-anim {
        transition: all 500ms ease-in-out 1000ms;
    }
    .dash-anim {
        div {
            transition: width 600ms ease-in-out 1000ms;
        }
    }
    .container-arc {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        svg {
            width: 50%;
            path {
                stroke-dasharray: 800;
                stroke-dashoffset: 1600;
                transition: all 600ms ease-in-out;
            }
        }
    }
    .container-text {
        text-align: center;
        margin-bottom: 30px;
    }
    .container-titles {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .title {
            text-align: center;
            width: 50%;
            &:last-child {
                margin-top: 20px;
            }
            .title-5 {
                margin-bottom: 10px;
                z-index: 2;
            }
        }
    }
    @media screen and (min-width: 800px) {
        grid-column: 3/13;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        .container-arc {
            justify-content: center;
            width: initial;
            grid-column: 3/9;
            margin-bottom: -170px;
            svg {
                width: unset;
            }
        }
        .container-text {
            grid-column: 4/8;
            margin-bottom: 50px;
        }
        .container-titles {
            grid-column: 1/11;
            .title {
                width: calc(100% / 3);
                .title-5 {
                    text-align: center;
                    margin: 0 auto;
                    font-size: 3.5rem;
                    line-height: 5rem;
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media (min-width: 800px) and (max-width: 1180px) {
        .container-arc {
            margin-bottom: -120px;
        }
    }
`;

const TripleTitle = ({ className, data }) => {
    const { width } = useViewport();

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    const buildArc = () => {
        if (width > 800) {
            return (
                <Fragment>
                    <ArcLeft />
                    <ArcRight />
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <ArcLeftM />
                    <ArcRightM />
                </Fragment>
            );
        }
    };
    return (
        <Wrapper
            className={`${className} ct ${inView ? 'anim' : ''}`}
            ref={ref}
        >
            <div className="container-arc">{buildArc()}</div>
            <div className="container-text p-anim">
                <p
                    className="title-5 "
                    dangerouslySetInnerHTML={{ __html: data.text }}
                ></p>
            </div>
            <div className="container-titles">
                {data.titles.map((item) => (
                    <div className="title" key={item.title}>
                        <Parallax y={[-25, 25]}>
                            <h4
                                className="title-5 title-anim"
                                dangerouslySetInnerHTML={{ __html: item.title }}
                            ></h4>
                            <HorizontalDash className="center dash-anim" />
                        </Parallax>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
};

export default TripleTitle;
