import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 20px;
    &.center {
        margin: 0 auto;
        div {
            margin: 0 auto;
        }
    }
    div {
        display: block;
        width: 30px;
        height: 3px;
        background-color: white;
        opacity: 1;
    }
    @media screen and (min-width: 800px) {
        margin-top: 30px;
        div {
            width: 40px;
        }
        &.center {
            margin: 0 auto;
            div {
                margin: 0 auto;
            }
        }
    }
`;

export default function HorizontalDash({ className }) {
    return (
        <Wrapper className={className}>
            <div></div>
        </Wrapper>
    );
}
