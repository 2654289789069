import { graphql } from 'gatsby';
import React from 'react';
import DoubleColumnText from '../components/common/DoubleColumnText';
import FooterMountains from '../components/common/FooterMountains';
import Header from '../components/common/Header';
import KeyNumbers from '../components/common/KeyNumbers';
import Push from '../components/common/Push';
import TripleTitle from '../components/common/TripleTitle';
import VerticalLine from '../components/common/VerticalLine';
import Layout from '../components/layout';
import SEO from '../components/SEO/SEO';

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "About" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageAbout {
                        header {
                            subtitle
                            title
                            type
                        }
                        double_column_text_1 {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        double_column_text_2 {
                            label
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        key_numbers {
                            label
                            list_number {
                                legend
                                number
                            }
                        }
                        push {
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                        triple_title {
                            text
                            titles {
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

const About = ({ data, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    data = data.allWpPage.edges[0].node.acfPageAbout;

    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header data={data.header} />
            <DoubleColumnText data={data.double_column_text_1} />
            <KeyNumbers data={data.key_numbers} />
            <DoubleColumnText data={data.double_column_text_2} />
            <TripleTitle data={data.triple_title} />
            <VerticalLine />
            <Push data={data.push} />
            <FooterMountains />
        </Layout>
    );
};

export default About;
